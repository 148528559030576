import React, { useMemo, useState } from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import { useTheme } from "styled-components"
import GalleryHeader from "@components/klips/gallery-header"
import { ServiceGrid } from "@components/gallery/grid"
import Grid from "@components/elements/grid"
import { Dropdown } from "@components/elements/dropdown"
import { Input } from "@components/elements/input"

const KlipsIntegrations = ({ data, pageContext }) => {
  const services = data.services.edges
  const { color } = useTheme()

  const [search, setSearch] = useState("")
  const [selectedType, setSelectedType] = useState("all")

  const handleTypeChange = event => {
    setSelectedType(event.target.value)
  }

  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  const types = useMemo(() => {
    return Array.from(
      new Set(services.flatMap(({ node: service }) => service.type))
    ).sort()
  }, [services])

  const filteredServices = useMemo(() => {
    return services.filter(
      ({ node: service }) =>
        (selectedType === "all" || service.type.includes(selectedType)) &&
        service.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [services, selectedType, search])

  return (
    <Layout
      seo={{
        title: "Klips Data Dashboard Integrations",
        description: `Dashboard integrations and pre-built connectors for over 100 of your favourite apps. Start a free trial, find an integration, and add it to your dashboard.`,
      }}
      fullWidth={true}
    >
      <article>
        <GalleryHeader
          name={"Connect your data with Klips"}
          cta="Track KPIs in minutes"
          subtitle={`Connect to multiple data sources at once. Whether your data lives in the cloud, spreadsheets, on-premise servers, or anywhere else, our 100s of pre-built connectors and REST API have you covered.`}
          shadow={false}
          kpi
          links={[
            { text: "Klips", url: "/klips" },
            {
              text: "Integrations",
            },
          ]}
        />
        <Div container>
          <Grid
            columns="1fr 1fr"
            columnsSm="1fr"
            gap="2rem"
            padding="2rem"
            margin="0 0 2rem"
            borderRadius="1rem"
            border={`1px solid lightgrey`}
            width="100%"
          >
            <Flex direction="column">
              <Paragraph fontSize="1rem" margin="0 0 .5rem">
                Search
              </Paragraph>
              <Input
                value={search}
                placeholder={`e.g. Google Analytics`}
                onChange={handleSearchChange}
              />
            </Flex>
            <Flex direction="column">
              <Paragraph fontSize="1rem" margin="0 0 .5rem">
                Select a Category
              </Paragraph>
              <Dropdown value={selectedType} onChange={handleTypeChange}>
                <option value="all">All Categories</option>
                {types.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Dropdown>
            </Flex>
          </Grid>
          <Div
            padding="2rem"
            margin="0 0 2rem"
            borderRadius="1rem"
            border={`1px solid ${color.indigo100}`}
            width="100%"
          >
            <ServiceGrid services={filteredServices} />
          </Div>
        </Div>
      </article>
    </Layout>
  )
}

KlipsIntegrations.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default KlipsIntegrations

export const pageQuery = graphql`
  query KlipsIntegrationsTemplate {
    services: allGalleryServices(
      filter: { status: { eq: "published" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          name
          logo {
            ...imageData
          }
          slug
          type
          docs
          just_docs
        }
      }
    }
  }
`
